import { Alert, AlertTitle, Box, Container, Stack } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { FC } from 'react';

type Props = {
  readonly header: string;
  readonly body: string;
  readonly sentryText: string;
  readonly sentryId?: string;
  readonly staticPrefix?: string;
};

const App: FC<Props> = ({ header, body, sentryText, sentryId, staticPrefix = '/static' }: Props) => {
  return (
    <div>
      <CssBaseline />
      <Container component="main" maxWidth="sm">
        <Stack alignItems="center" spacing={6} mt="20%">
          <Box pl={5.5}>
            <img
              src={`${staticPrefix}/img/logo_05.svg`}
              alt="medialoopster"
              style={{ height: '52px', width: 'auto' }}
              draggable={false}
            />
          </Box>
          <Alert severity="info">
            <AlertTitle>{header}</AlertTitle>
            {sentryId && (
              <p>{sentryText} <strong>{sentryId}</strong>.</p>
            )}
            {body}
          </Alert>
        </Stack>
      </Container>
    </div>
  );
};

export default App;
