import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import ReactDOM from 'react-dom';

import medialoopsterTheme from 'medialoopster/medialoopsterTheme';

import App from './App';

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={medialoopsterTheme}>
      <App header={global.header} body={global.body} sentryText={global.sentryText} sentryId={global.sentryId} />
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById('app'),
);
